/* components/ToastNotification.css */
.toast-container {
    position: fixed;
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1050;
    width: 90%;
    max-width: 400px;
}

.toast {
    visibility: hidden;
    background-color: #ffc107;
    color: black;
    text-align: center;
    border-radius: 0.25rem;
    padding: 1rem;
    margin: 1rem auto;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    transition: visibility 0s, opacity 0.5s linear;
    opacity: 0;
}

.toast.show {
    visibility: visible;
    opacity: 1;
}

.toast-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 0.5rem;
}

.toast-header .btn-close {
    background: none;
    border: none;
    color: black;
    font-size: 1.25rem;
    cursor: pointer;
}

.toast-body {
    font-size: 1rem;
}

.toast-body .visit-btn {
    display: inline-block;
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 0.25rem;
    transition: background-color 0.3s;
}

.toast-body .visit-btn:hover {
    background-color: #0056b3;
}

